
import { Component, Vue, Prop } from 'vue-property-decorator';

import { FlexAlignment } from '../../app_code/FlexAlignment';

@Component
export default class SectionTitle extends Vue {
    @Prop()
    private readonly alignment!: FlexAlignment;

    @Prop()
    private readonly lineLeft!: boolean;
}

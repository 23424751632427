
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class LinkButton extends Vue {
  @Prop()
  private readonly href!: string;

  @Prop()
  private readonly external!: boolean;

  @Prop({ default: '_blank' })
  private readonly target!: string;

  @Prop()
  private readonly colour!: string;

  @Prop()
  private readonly backgroundColour!: string;

  @Prop()
  private readonly icon!: string;

  @Prop({ default: 'white' })
  private readonly iconColour!: string;

  @Prop()
  private readonly block!: boolean;

  clicked(): void {
    this.$emit('clicked');

    if (this.external) {
      if (this.target === '_self') {
        window.location.href = this.href;
      } else {
        window.open(this.href, this.target);
      }
    } else {
      this.$router.push(this.href);
    }
  }
}

import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  // About
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
  // For Sale
  {
    path: '/properties-for-sale',
    name: 'Properties For Sale',
    component: () => import('../views/PropertiesForSale.vue'),
  },
  {
    path: '/sold-properties',
    name: 'Sold Properties',
    component: () => import('../views/PropertiesSold.vue'),
  },
  // Rentals
  {
    path: '/properties-for-rent',
    name: 'Properties For Rent',
    component: () => import('../views/PropertiesForRent.vue'),
  },
  {
    path: '/maintenance-request',
    name: 'Maintenance Request',
    component: () => import('../views/MaintenanceRequest.vue'),
  },
  {
    path: '/notice-of-leave',
    name: 'Notice Of Leave',
    component: () => import('../views/NoticeOfLeave.vue'),
  },
  // Testimonials
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: () => import('../views/Testimonials.vue'),
  },
  // Contact
  {
    path: '/contact',
    name: 'Contact Us',
    component: () => import('../views/ContactUs.vue'),
  },
  // Other Pages
  {
    path: '/listings/:id',
    name: 'Listing',
    meta: {
      hide: true,
    },
    component: () => import('../views/ListingDetails.vue'),
  },
  // Not being used
  {
    path: '/forms/rental-appraisal',
    name: 'Rental Appraisal',
    component: () => import('../views/RentalAppraisal.vue'),
  },
  {
    path: '/forms/sales-appraisal',
    name: 'Sales Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  {
    path: '/maintenance-request',
    name: 'Maintenance Request',
    component: () => import('../views/MaintenanceRequest.vue'),
  },
  {
    path: '/tv-display/sales',
    name: 'TV Display Sales',
    component: () => import('../views/TVDisplaySales.vue'),
    meta: {
      hide: true,
      hideAncillaries: true, // Disables the header and footer
    },
  },
  {
    path: '/tv-display/rentals',
    name: 'TV Display Rentals',
    component: () => import('../views/TVDisplayRentals.vue'),
    meta: {
      hide: true,
      hideAncillaries: true, // Disables the header and footer
    },
  },
  // Page Ids
  {
    path: '/listings/:id/tenanacy-agreement',
    name: 'Tenanacy Agreement',
    meta: {
      hide: true,
    },
    component: () => import('../views/ListingTenanacyAgreement.vue'),
  },
  {
    path: '/listings/:id/make-an-offer',
    name: 'Make an Offer',
    meta: {
      hide: true,
    },
    component: () => import('../views/ListingMakeAnOffer.vue'),
  },
  {
    path: '/profiles/:id',
    name: 'Profile',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/profiles/:id/properties',
    name: 'Profile Listings',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListings.vue'),
  },
  {
    path: '/profiles/:id/properties/sold',
    name: 'Profile Sold Listings',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListingsSold.vue'),
  },
  {
    path: '/profiles/:id',
    name: 'Profile',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: () => import('../views/Unsubscribe.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    meta: {
      hide: true,
    },
    component: () => import('../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;

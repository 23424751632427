
import { Component, Vue } from 'vue-property-decorator';
import {
  Office,
  OfficeExternalLinkType,
  WebsiteLevel,
  Testimonial,
} from 'client-website-ts-library/types';
import {
  API,
  Config,
  Logger,
  LogLevel,
} from 'client-website-ts-library/services';

import { TestimonialFilter } from 'client-website-ts-library/filters';
import TestimonialList from '../Testimonials/TestimonialList.vue';
import TestimonialCycler from '../Cycler/TestimonialCycler.vue';

@Component({
  components: {
    TestimonialList,
    TestimonialCycler,
  },
})
export default class Footer extends Vue {
  private office: Office | null = null;

  private filter: TestimonialFilter = new TestimonialFilter();

  private testimonials: Testimonial[] | null = null;

  mounted() {
    this.loadOffice();

    API.Testimonials.Search(this.filter).then((testimonials) => {
      this.testimonials = testimonials;
    });
  }

  loadOffice(): void {
    switch (Config.Website.Settings!.WebsiteLevel) {
      case WebsiteLevel.Profile:
        API.Profiles.Get(Config.Website.Settings!.WebsiteId, true).then(
          (profile) => {
            this.office = profile.Office;
          },
        );

        break;
      case WebsiteLevel.Office:
        API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
          this.office = office;
        });

        break;
      default:
        Logger.Log(
          LogLevel.Error,
          'Failed to load office for website. Website level is invalid.',
        );
        break;
    }
  }

  get facebookLink(): string | undefined {
    return this.office?.ExternalLinks.find(
      (l) => l.Type === OfficeExternalLinkType.Facebook
    )?.Url;
  }

  get youtubeLink(): string | undefined {
    return this.office?.ExternalLinks.find(
      (l) => l.Type === OfficeExternalLinkType.YouTube
    )?.Url;
  }

  get twitterLink(): string | undefined {
    return this.office?.ExternalLinks.find(
      (l) => l.Type === OfficeExternalLinkType.Twitter
    )?.Url;
  }

  get googleMyBusinessLink(): string | undefined {
    return this.office?.ExternalLinks.find(
      (l) => l.Type === OfficeExternalLinkType.GoogleMyBusiness
    )?.Url;
  }

  get linkedinLink(): string | undefined {
    return this.office?.ExternalLinks.find(
      (l) => l.Type === OfficeExternalLinkType.LinkedIn
    )?.Url;
  }

  get pinterestLink(): string | undefined {
    return this.office?.ExternalLinks.find(
      (l) => l.Type === OfficeExternalLinkType.Pinterest
    )?.Url;
  }

  get instagramLink(): string | undefined {
    return this.office?.ExternalLinks.find(
      (l) => l.Type === OfficeExternalLinkType.Instagram
    )?.Url;
  }

  // eslint-disable-next-line
  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  // eslint-disable-next-line
  get year(): string {
    return new Date().getFullYear().toString();
  }
}

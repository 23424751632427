
import { BrowserStorageService, ServiceManager, ServiceType } from 'client-website-ts-library/services';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class WelcomeModal extends Vue {
  private active = false

  @Prop()
  private readonly title!: string;

  @Prop({ default: 'Ok' })
  private readonly buttonText!: string;

  mounted() {
    const bss = ServiceManager.Require(ServiceType.BrowserStorage) as BrowserStorageService;

    this.active = bss.getItem('hasShownWelcomeModal') !== 'true';

    if (!this.active) bss.setItem('hasShownWelcomeModal', 'true');
  }

  handleClick(): void {
    this.active = false;
  }
}

